<template>
  <div class="page-bg">
    <div class="demo-main">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/demo' }">Demo</el-breadcrumb-item>
        <el-breadcrumb-item>Free-choice speech assessment</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">Speech Assessment of Free-choice</div>
      <div class="sub-title">Please select the sentence you would like to read aloud.</div>
      <div class="sub-title-little">If multiple sentences are read aloud, display the sentence with the highest match.</div>
      <div v-if="startSoe" class="countdown">{{ second }}s</div>
      <div class="demo-container">
        <div class="ref-text-part">
          <div class="ref-text-content">
            <div class="ref-text">
              <div class="question-stem">
                <div class="stem-item">
                  <ul v-if="!hasResult">
                    <li v-for="(item,index) in subject[stemNum].stemList" :key="index">
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                  <ul v-if="hasResult&&wordInfo.length===0">
                    <li v-for="(item,index) in subject[stemNum].stemList" :key="index">
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                  <ul v-if="hasResult&&wordInfo.length!==0">
                    <li v-for="(item,index) in subject[stemNum].stemList" :key="index">
                      <span v-if="index === newWordInfo">
                        {{newWordInfo+1}}、<span v-for="(items,index) in wordInfo" :key="index" :class="filter_color('normal',items.score)">{{items.refText}}&nbsp;</span>
                      </span>
                      <span v-else>{{ item }}</span>
                    </li>
                  </ul>
                  <ul v-if="hasResult&&wordInfo.length!==0" class="freechoiseResultType">
                    <li><span class="point1"></span><span>Very standard</span></li>
                    <li><span class="point2"></span><span>Average</span></li>
                    <li><span class="point3"></span><span>Not standard</span></li>
                  </ul>
                </div>
                <div v-if="!startSoe" class="change-stem">
                  <el-button @click="stemNum--;hasResult = false;" type="text" size="medium" :disabled="stemNum===0"><i class="el-icon-arrow-left"></i>Previous</el-button>
                  <el-button @click="stemNum++;hasResult = false;" type="text" size="medium" :disabled="stemNum===4">Next<i class="el-icon-arrow-right"></i></el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-recorder">
          <div @click="startEvaluation" v-if="recorderStatus === 1" class="recorder-box"><img src="~@/assets/images/soe_demo/demo/reg_start.png" alt=""></div>
          <div v-if="recorderStatus === 0" class="recorder-box recorder-box-loading">
            <ul class="loading-taste-line">
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
            </ul>
          </div>
          <div @click="stopEvaluation" v-if="recorderStatus === 2" class="recorder-box-stop">
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
            <div class="recorder-stop">
              <span></span>
            </div>
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
          </div>
        </div>
      </div>
      <el-divider v-if="hasResult"></el-divider>
      <div v-if="hasResult" class="result-detail">
        <div class="result-overall">
          <div class="text">Overall</div>
          <div class="play-replay">
            <span>{{ overall }}</span>
            <img v-if="!ifplayAudio_replay" @click="playAudio_replay" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
            <img v-if="ifplayAudio_replay" @click="stopAudio_replay" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
            <audio ref="audioElement_replay" @ended="onAudioEnded_replay" :src="audioUrl"></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Phoneme',
  data () {
    return {
      soe: null,

      ifplayAudio_replay: false,

      recorderStatus: 1,

      startSoe: false,
      second: 30,
      loanTime: '',

      stemNum: 0,
      subject: [
        {
          index: 0,
          stemList: [
            "1、We stayed there for five days.",
            "2、I went to Guilin with my family last week.",
            "3、The hills there are greener and the water is cleaner.",
            "4、We visited some places of interest, which many people visit every day."
          ],
          answer: [{
            text: "We stayed there for five days."
          },{
            text: "I went to Guilin with my family last week."
          },{
            text: "The hills there are greener and the water is cleaner."
          },{
            text: "We visited some places of interest, which many people visit every day."
          }],
        },{
          index: 1,
          stemList: [
            "1、Do you like music?",
            "2、I like some relaxing music.",
            "3、I think music is an important part of our lives.",
            "4、My brother likes dance music and rock music very much, because he thinks they are amazing."
          ],
          answer: [{
            text: "Do you like music?"
          },{
            text: "I like some relaxing music."
          },{
            text: "I think music is an important part of our lives."
          },{
            text: "My brother likes dance music and rock music very much, because he thinks they are amazing."
          }],
        },{
          index: 2,
          stemList: [
            "1、What a great man he was!",
            "2、Thomas Edison was a great American inventor.",
            "3、At the age of 16, he saved a boy who was playing on the railway.",
            "4、He always asked some strange questions and most of them had nothing to do with his lessons."
          ],
          answer: [{
            text: "What a great man he was!"
          },{
            text: "Thomas Edison was a great American inventor."
          },{
            text: "At the age of 16, he saved a boy who was playing on the railway."
          },{
            text: "He always asked some strange questions and most of them had nothing to do with his lessons."
          }],
        },{
          index: 3,
          stemList: [
            "1、To be safe.",
            "2、It's important for us to keep safe at school.",
            "3、We mustn't throw rubbish out of the windows of the tall buildings.",
            "4、We are not allowed to smoke or drink. If we follow these rules, we'll be safe at school."
          ],
          answer: [{
            text: "To be safe."
          },{
            text: "It's important for us to keep safe at school."
          },{
            text: "We mustn't throw rubbish out of the windows of the tall buildings."
          },{
            text: "We are not allowed to smoke or drink. If we follow these rules, we'll be safe at school."
          }],
        },{
          index: 4,
          stemList: [
            "1、Good morning. This is CCTV news.",
            "2、That's all. Thanks for your listening!",
            "3、I think some are useful, such as \"Don't be late\".",
            "4、We have better rules, such as we can design our own work uniforms, and we can wear our own clothes during the weekdays."
          ],
          answer: [{
            text: "Good morning. This is CCTV news."
          },{
            text: "That's all. Thanks for your listening!"
          },{
            text: "I think some are useful, such as \"Don't be late\"."
          },{
            text: "We have better rules, such as we can design our own work uniforms, and we can wear our own clothes during the weekdays."
          }],
        }
      ],

      hasResult: false,

      overall: '',
      wordInfo: [],
      answer: [],
      newWordInfo: '',
      hitNumber: '',
      audioUrl: '',
    }
  },
  mounted() {
    this.soe = new this.$SoeEngine({
      preService: this.$globalParam.preService,
      log: this.$globalParam.log,
      appId: this.$globalParam.appId,
      appSecret: this.$globalParam.appSecret,
      coreType: {
        langType: 'en-US',
        format: 'mp3', // 实时录音时可不传值；上传文件时传音频格式，支持 mp3、wav、pcm
        sampleRate: 16000, // 音频采样率（Hz）：当前仅支持 16000
        looseness: 4, // 评分宽松度，范围：0-9，数值越大越宽松
        connectTimeout: 15, // 连接超时时间（秒），范围：5-60
        responseTimeout: 15, // 响应超时时间（秒），范围：5-60
        scale: 100, // 评分分制，范围：1-100
        ratio: 1, // 评分调节系数，范围：0.8-1.5
        userId: '',
        audioUrl: true,
      },
      params: {
        mode: "", // 评测模式：基础题型 word/sentence/chapter  高阶题型 qa/topic/retell
        // 基础题型（词、句、篇章）
        refText: "", // 测评对照阅读的文本：
        // 高阶题型（问答题）
        stem: { // 题干
          para: "", // 段落内容
          question: "", // 提问内容
        },
        distractor: [],
        // 高阶题型（问答题）&& 高阶题型（看图说话/口语作文）&& 高阶题型（复述）
        answer: [],
        keyword: [],
      },
      micAllowCallback: () => {
        console.log('=====The microphone has been allowed.=====');
      },
      micForbidCallback: (msg) => {
        console.error('====='+msg+'=====');
        this.recorderStatus = 1
        this.startSoe = false
        this.$message.error({message: msg, duration: '2000',})
      },
      engineFirstInitDone: () => {
        console.log('=====Initialization successful.=====');
      },
      engineFirstInitFail: (status,msg) => {
        console.error('====='+status+':'+msg+'=====');
        this.recorderStatus = 1
        this.$message.error({message: msg, duration: '2000',})
      },
      getStarted: () => {
        this.recorderStatus = 2
        this.loanTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.loanTime)
            this.loanTime = ''
            this.second = 30
            this.stopEvaluation()
          }
        }, 1000)
      },
      engineBackResultDone: (msg) => {
        this.recorderStatus = 1
        console.log(msg);
        this.getResult(msg)
      },
      engineBackResultWarning: (status,msg) => {
        console.log(status,msg)
      },
      engineBackResultError: (status,msg) => {
        console.log(status,msg)
        this.recorderStatus = 1
        this.startSoe = false
        this.$message.error({message: msg, duration: '2000',})
      },
      playAudioComplete: () => {
        console.log('Playback complete.');
      },
      playAudioError: () => {
        this.recorderStatus = 1
        this.$message.error({message: "Playback error", duration: '2000',})
      },
      micVolumeCallback: (data) => {
        console.log('Recording volume level is:' + data);
      },
      noNetwork: () => {
        this.recorderStatus = 1
        this.$message.error({message: "No network available", duration: '2000',})
      },
      recorderBlob: (blob) => {
        console.log(blob)
      },
    })
  },
  methods: {
    // 开始评测
    startEvaluation() {
      this.recorderStatus = 0
      this.soe.params.mode = "elective";
      this.soe.params.answer = []
      this.soe.params.answer = this.subject[this.stemNum].answer
      let params = this.soe.params;
      this.soe.startRecord(params);
      this.startSoe = true
    },
    // 结束评测
    stopEvaluation() {
      clearInterval(this.loanTime)
      this.loanTime = ''
      this.second = 30
      this.soe.stopRecord();
      this.startSoe = false
    },
    // 处理评测结果
    getResult(result) {
      console.log(result)
      let newWord = ''
      let newWordIndex = result.index
      for(let k=0;k<result.wordInfo.length;k++) {
        newWord = newWord+result.wordInfo[k].refText+' '
      }
      newWord = newWord.trim()
      let newAnswer = result.params.request.params.answer
      let hitPoint = ''
      for(let i=0;i<result.params.request.params.answer.length;i++) {
        newAnswer[i].text=i+1+'、'+result.params.request.params.answer[i].text
        if(result.params.request.params.answer[i].text.indexOf(newWord)>=0) {
          newWord=i+1+'、'+newWord
          hitPoint=i+1+'、'
        }
      }
      this.overall = (result.overall).toFixed(0)
      this.wordInfo = result.wordInfo
      this.answer = newAnswer
      this.newWordInfo = newWordIndex
      this.hitNumber = hitPoint

      this.audioUrl = result.audioUrl
      this.hasResult = true
    },
    playAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.play();
      this.ifplayAudio_replay = true
    },
    stopAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.pause();
      audioElement_replay.currentTime = 0;
      this.ifplayAudio_replay = false
    },
    onAudioEnded_replay() {
      this.ifplayAudio_replay = false
    },

    filter_color(type,score) {
      let color = ''
      if (type === "normal") {
        if (score >= 85) {
          color = 'standard'
        } else if (score >= 60 && score < 85) {
          color = 'general'
        }else if (score < 60) {
          color = 'non'
        }
      } else if (type === "delete") {
        color = 'unread'
      } else if (type === "replace") {
        color = 'non'
      } else {
        color = 'readmore'
      }
      return color
    },
  }
}
</script>

<style scoped lang="less">
.page-bg {
  border: 1px solid transparent;
}
.demo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 1140px;
  padding: 88px 30px 30px;
  margin: 30px auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .breadcrumb {
    position: absolute;
    left: 30px;
    top: 30px;
    margin-bottom: 30px;
  }
  .title {
    line-height: 38px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
  }
  .sub-title {
    line-height: 28px;
    margin: 70px auto 0;
    color: #333;
    text-align: center;

    font-size: 20px;
  }
  .sub-title-little {
    line-height: 28px;
    margin: 10px auto 0;
    color: #333;
    text-align: center;

    font-size: 18px;
  }
  .countdown {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
    color: #fff;
  }
  .demo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 0;
    .ref-text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1040px;
      height: auto;
      margin: 0 auto;
      .ref-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 70%;
        font-weight: 500;
        font-size: 40px;
        color: #333;
        .question-stem {
          width: 100%;
          .stem-item {
            ul {
              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                margin-bottom: 5px;
                font-weight: 500;
                font-size: 24px;
                span {
                  i {
                    font-style: normal;
                    color: #666;
                  }
                }
                img {
                  width: 30px;
                }
              }
              li.active-right {
                color: #fff;
                background: #8FCDA4;
              }
              li.active-error {
                color: #fff;
                background: #DE7470;
              }
            }
            .freechoiseResultType {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              width: 100%;
              margin-top: 30px;
              li {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 20px;
                font-weight: 400;
                font-size: 14px;
                color: #000;
                span:first-child {
                  width: 10px;
                  height: 10px;
                  margin-right: 10px;
                  border-radius: 100%;
                }
                .point1 {
                  background: #25A926;
                }
                .point2 {
                  background: #212222;
                }
                .point3 {
                  background: #F90A01;
                }
              }
            }
          }
          .change-stem {
            text-align: right;
            .el-button {
              margin: 0 20px;
              font-weight: 500;
              font-size: 16px;
            }
          }
        }
      }
    }
    .btn-recorder {
      position: relative;
      height: 80px;
      margin-top: 23px;
      cursor: pointer;
      .recorder-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
        box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
        cursor: pointer;
        img {
          width: 50%;
        }
      }
      .recorder-box-loading {
        background: #DAEAFF;
        .loading-taste-line {
          position: absolute;
          width: 40px;
          height: 40px;
        }
        .item{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          animation: xuanzhuan 4s linear infinite;
        }
        .item:nth-child(1){
          animation-delay: 0.15s;
        }
        .item:nth-child(2){
          animation-delay: 0.3s;
        }
        .item:nth-child(3){
          animation-delay: 0.45s;
        }
        .item:nth-child(4){
          animation-delay: 0.6s;
        }
        .item:nth-child(5){
          animation-delay: 0.75s;
        }
        .item::after{
          content:'';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: royalblue;
        }
        @keyframes xuanzhuan{
          75%{
            transform: rotate(650deg);
          }
          79%{
            transform: rotate(720deg);
            opacity: 1;
          }
          80%{
            transform: rotate(720deg);
            opacity: 0;
          }
          100%{
            transform: rotate(810deg);
            opacity: 0;
          }
        }
      }
      .recorder-box-stop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 80px;
        .recorder-stop {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          margin: 0 10px;
          border-radius: 100%;
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
          cursor: pointer;
          span {
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 3px;
          }
        }
        .start-taste-line {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-taste-line li {
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          width: 4px;
          height: 6px;
          margin: 0 2px;
          display: inline-block;
          border: none;
          border-radius: 1px;
        }
        li {
          animation: note 0.3s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .hr1 {
          animation-delay: -1s;
        }
        .hr2 {
          animation-delay: -0.9s;
        }
        .hr3 {
          animation-delay: -0.8s;
        }
        .hr4 {
          animation-delay: -0.9s;
        }
        .hr5 {
          animation-delay: -1s;
        }
        @keyframes note {
          from {
            transform: scaleY(1);
          }
          to {
            transform: scaleY(5);
          }
        }
      }
    }
  }
  .result-detail {
    width: 100%;
    .result-overall {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 20px;
        color: #1377e1;
        line-height: 25px;
      }
      .play-replay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 70px;
        margin-top: 10px;
        background: #F5F9FF;
        border-radius: 36px;
        span {
          height: 40px;
          line-height: 30px;
          margin-right: 10px;
          font-weight: 500;
          font-size: 40px;
          color: #1377e1;
        }
        img {
          width: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>